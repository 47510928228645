<template lang="pug">
div( class="w-full px-4 xl:px-0 xl:w-8/12 2xl:max-w-4xl mx-auto" )
  v-tabs.mb-8.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden(
    grow
    v-model="tab"
  )
    v-tab Organization

    v-tabs-items( v-model="tab" )
      v-tab-item.pa-4.pa-md-8.border-t
        .skellies( v-if="isOrgLoading" )
          v-skeleton-loader.mb-8(
            height="150"
            type="image"
          )

          v-skeleton-loader(
            v-if="isOrgLoading"
            height="150"
            type="image"
          )

        FormWrapper(
          v-if="!isOrgLoading"
          :item="organization"
        )
          template( v-slot="{ clone, valid, rules, save }" )
            div.mb-8
              h5 Details
              v-divider.mb-8

              v-text-field(
                label="Organization"
                hide-details
                dense
                outlined
                v-model="clone.name"
                :rules="[rules.required]"
              )

              AddressForm.mt-6(
                :rules="rules"
                :clone="clone.address"
              )

              h5.mt-8 Status selection
              v-divider.mb-6

              .d-flex.gap-16.justify-between
                div.w-full
                  h5.d-flex
                    span.mr-1 Who
                    v-icon( color="green" small ) mdi-check
                    span.ml-1 approves expenses?
                  div.text-xs.text-gray-500 Select those who can approve expenses that have been submitted

                  v-select(
                    class="mt-6 w-full"
                    :loading="teamMembersPending"
                    :items="withDisabledAdmins"
                    dense
                    multiple
                    outlined
                    label="Team member"
                    item-value="id"
                    item-text="fullName"
                    v-model="clone.expensesApprovedBy"
                    hint="Admin approval permissions cannot be revoked"
                    persistent-hint,
                    :rules="[rules.required]"
                  )
                    template(v-slot:item="{ item, on }")
                      v-list-item( :value="item.value" v-on="on" :class="{ 'border-b bg-gray-100': item.disabled }" )
                        v-list-item-action
                          v-checkbox(
                            :disabled="item.disabled"
                            :input-value="clone.expensesApprovedBy.includes(item.id)"
                          )
                        v-list-item-content
                          v-list-item-title(:style="{ color: item.condition ? '#FF5733' : '#000000' }") {{ item.fullName }}

                div.w-full
                  h5.d-flex
                    span.mr-1 Who
                    v-icon( color="blue" small ) mdi-check-all
                    span.ml-1 confirms expenses?
                  div.text-xs.text-gray-500 Leave blank if you wish to exclude the confirm status

                  v-select(
                    class="mt-6 w-full"
                    :loading="teamMembersPending"
                    :items="teamMembers"
                    dense
                    multiple
                    outlined
                    label="Team member"
                    item-value="id"
                    item-text="fullName"
                    v-model="clone.expensesConfirmedBy"
                  )

              h5.mt-8 Email Notification
              v-divider.mb-6

              .d-flex.gap-16.justify-between
                .w-full
                  h5
                    span.mr-1 Who receives emails on
                    v-icon( color="green" small ) mdi-check
                    span.ml-1 approved expenses?
                  div.text-xs.text-gray-500 List those who will be notifed via email upon expense approval

                  v-textarea.mt-4.text-sm.w-full(
                    dense
                    rows="4"
                    auto-grow
                    outlined
                    label="Email"
                    v-model="clone.approveEmails"
                    persistent-hint
                    hint="To send to multiple emails, separate each by a comma"
                  )

                .w-full
                  h5.d-flex
                    span.mr-1 Who receives emails on
                    v-icon( color="blue" small ) mdi-check-all
                    span.ml-1 confirmed expenses?
                  div.text-xs.text-gray-500 List those who will be notifed via email upon expense confirmation

                  v-textarea.mt-4.text-sm(
                    dense
                    rows="4"
                    auto-grow
                    :disabled="clone.expensesConfirmedBy.length === 0"
                    outlined
                    label="Email"
                    v-model="clone.confirmEmails"
                    persistent-hint
                    hint="To send to multiple emails, separate each by a comma"
                  )

            v-divider

            v-btn.mt-8(
              @click="saveOrgSettings(save, clone)"
              depressed
              block
              :disabled="!valid"
              color="primary"
            ) Save
</template>

<script>
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import useApiGet from '@/api/useApiGet'
import FormWrapper from '../../components/FormWrapper.vue'
import AddressForm from '@/components/Address.Form'

export default {
  name: 'OrgSettings',

  components: {
    AddressForm,
    FormWrapper
  },

  setup(props, { root: { $store, $FeathersVuex, $snackSuccess, $snackError } }) {
    const {
      organization: { id }
    } = $store.getters['auth/user'];
    const { User, Organization } = $FeathersVuex.api

    const organizationParams = computed(() => ({
      query: {
        $select: [
          'approveEmails',
          'confirmEmails',
          'expensesApprovedBy',
          'expensesConfirmedBy',
          'address',
          'name'
        ]
      }
    }))
    const { item: organization, isPending: isOrgLoading } = useApiGet({
      model: Organization,
      id,
      params: organizationParams
    })

    console.log(organization)

    const {
      items: teamMembers,
      isPending: teamMembersPending
    } = useFind({
      model: User,
      params: {
        query: {
          status: 'active'
        }
      }
    })
    const withDisabledAdmins = computed(() => {
      const adminsDisabled = teamMembers.value
        .map(i => (
          i.roleId === 5
          ? i.clone({ disabled: true })
          : i
        ));

      return adminsDisabled;
    })

    const saveOrgSettings = async (save, clone) => {
      try {
        console.log(clone)
        await save()
        $snackSuccess('Organization Saved');
      } catch(e) {
        console.log(e);
        $snackError('Unable to save Organzation, please try again later')
      }
    }

    return {
      saveOrgSettings,

      organization,
      isOrgLoading,
      tab: 'organization',

      teamMembers,
      withDisabledAdmins,
      teamMembersPending
    }
  }
}
</script>

<style>

</style>